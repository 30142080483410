// ROW

.flex-center-center-row-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
}

.flex-center-center-row-nowrap {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row nowrap;
}

.flex-start-center-row-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: row wrap;
}

.flex-start-center-row-nowrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: row nowrap;
}

.flex-start-start-row-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-flow: row wrap;
}

.flex-start-start-row-nowrap {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-flow: row nowrap;
}

.flex-center-end-row-wrap {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-flow: row wrap;
}

.flex-space-between-center-row-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row wrap;
}

.flex-space-between-center-row-nowrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row wrap;
}

.flex-space-between-start-row-wrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-flow: row wrap;
}

.flex-space-between-start-row-nowrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-flow: row nowrap;
}

.flex-end-center-row-nowrap {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-flow: row wrap;
}

// COLUMNS

.flex-start-start-column-nowrap {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-flow: column nowrap;
}

.flex-end-center-column-nowrap {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-flow: column nowrap;
}

.flex-start-center-column-nowrap {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-flow: column nowrap;
}

.flex-start-center-column-wrap {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-flow: column wrap;
}

.flex-stretch-column-nowrap {
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  flex-flow: column nowrap;
}

.flex-center-start-column-nowrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column nowrap;
}

.flex-center-center-column-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column wrap;
}

.flex-center-center-column-nowrap {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
}

.flex-center-space-between-column-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: column wrap;
}

.flex-stretch-flex-start-column-nowrap {
  display: flex;
  justify-content: stretch;
  align-items: flex-start;
  flex-flow: column nowrap;
}

.flex-gap-10 {
  gap: 10px;
}

.flex-gap-20 {
  gap: 20px;
}

.flex-gap-12 {
  display: inline-flex;
  gap: 12px;
}

.flex-gap-30 {
  gap: 30px 16px;
}

.flex-gap-16-36 {
  gap: 36px 16px;
}
