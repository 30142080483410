@keyframes fadeIn {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}

ion-split-pane {
  --side-width: 260px;
}

.scrollable-doc {
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}

@media screen and (max-width: 600px) {
  .scrollable-doc {
    width: 100%;
  }
}

.unauthorized-view {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background: url('assets/background.webp');
  background-size: cover;
}

.login-logo {
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.page {
  padding: 16px 24px 16px 24px;
  width: 100%;
  max-width: 1200px;
}

.width-1line {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.truncate-1-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.truncate-2lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.list-client-screen {
  overflow-y: auto;

  @media (max-width: 1372px) {
    max-height: calc(100vh - 900px);
  }

  @media (min-width: 1373px) {
    max-height: calc(100vh - 700px);
  }
}

.list-padding-horizontal {
  padding-left: 4px;
  padding-right: 4px;
}

.nav-title {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
}

.zIndex99999 {
  position: relative;
  z-index: 999999 !important;
}

.zIndexMinus1 {
  position: static;
  z-index: 0 !important;
}

.clickablePill {
  max-width: 100px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.popover-date-picker {
  --width: 300px;
}

.scrollable {
  height: calc(100vh - 56px);
  height: calc(100dvh - 56px);
  overflow-y: auto;
}

.g-separator {
  height: 0.1px;
  background-color: var(--ion-color-light-contrast);
  width: 100%;
}

.b-tooltip {
  position: absolute;
  z-index: 10;
  right: -8px;
  top: -8px;
  cursor: pointer;
}

.new-sale-notes {
  min-width: 600px;
  max-width: 700px;
  min-height: 300px !important;
}

@media screen and (max-width: 600px) {
  .new-sale-notes {
    min-width: 300px;
  }
}

.flex1 {
  flex: 1;
}

.flex2 {
  flex: 2;
}

.textAlignEnd {
  text-align: end;
}

// EntityPaginationData
.entity-pagination-wrapper {
  position: fixed;
  top: 56px; // Header height
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.entity-pagination-filters {
  position: sticky;
  top: 0;
  z-index: 10;
  min-height: 60px;
  max-height: 224px;
  background: var(--ion-background-color);
}

.entity-pagination-content {
  flex: 1;
  min-height: 0;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.entity-pagination-list {
  flex: 1;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 64px;
}

.entity-pagination-footer {
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 16px;
  height: 60px;
  background-color: var(--ion-background-color);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 10;
}

.pagination-layout {
  padding: 0 24px 0 24px;
  width: 100%;
  max-width: 1200px;
}
