*::-webkit-scrollbar-track {
  -webkit-box-shadow: var(--ion-background-color);
  border-radius: 10px;
  background-color: var(--ion-background-color);
}

*::-webkit-scrollbar {
  width: 6px;
  background-color: var(--ion-background-color);
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}

.link-reset {
  text-decoration: none;
  color: inherit;
  padding: 0;
  margin: 0;
  background: none;
  border: none;
  outline: none;
  font: inherit;
  display: inline-block;
}

.mainBackground {
  background: var(--ion-background-color);
}

.itemBackground {
  background: var(--ion-item-background);
}

.border-success {
  border: 1px solid #0e8a40;
}

.border-error {
  border: 1px solid var(--ion-color-danger);
}

.g-search {
  border-radius: 6px;
  border: 1px solid var(--ion-color-medium);
  --border-radius: 6px;
  padding: 0;
}

.text-danger {
  color: var(--ion-color-danger-shade);
}

.text-success {
  color: #0e8a40;
}

.text-neutral {
  color: var(--ion-color-primary);
}

.text-shade {
  color: var(--ion-color-medium-shade);
}

.text-center {
  text-align: center;
}

.info-card {
  width: 100%;
  border-radius: 16px;
  padding: 24px 16px 24px 16px;
  background: var(--ion-item-background);
}

.settings-card-dimensions {
  width: 320px;
  height: 180px;
}

.g-select {
  padding: 12px 0 8px 0;
}

.g-static-select {
  height: 66px;
  padding-top: 20px;
}

.g-static-select-label {
  //padding: 10px 0 0 0;
  position: absolute;
  top: 4px;
  left: 0;
}

.g-select-border {
  border: 1px solid var(--ion-color-primary);
  background: var(--ion-item-background);

  &:focus-within {
    border: 1px solid var(--ion-color-success);
  }
}

.g-select-border-color-disabled {
  border: 1px solid var(--ion-color-primary);
}

.b-field-border {
  border: 1px solid var(--ion-color-primary);
  background: var(--ion-item-background);

  &:focus-within {
    border: 1px solid var(--ion-color-success);
  }
}

.b-field-border-error {
  border: 1px solid var(--ion-color-danger);
  background: var(--ion-item-background);
}

.b-input {
  --border-color: var(--ion-color-primary) !important;
  border-color: var(--ion-color-primary) !important;

  &:focus-within {
    --border-color: var(--ion-color-success) !important;
    border-color: var(--ion-color-success) !important;
  }
}

.b-disabled-border {
  border: 1px solid var(--ion-color-medium-shade);
}

.border-radius-input {
  border-radius: 6px;
  --border-radius: 6px;
}

.g-select-size {
  min-height: 67px;
  width: 300px;
}

.g-select-clear {
  position: absolute;
  top: 20px;
  right: 6px;
  z-index: 100;
  cursor: pointer;
}

.g-multi-select-header {
  position: absolute;
  top: 0px;
  right: 2px;
  z-index: 100;
  cursor: pointer;
  width: 300px;
  padding: 10px 6px 10px 0;
}

.cursorPointer {
  cursor: pointer;
}

.hoverable {
  &:hover {
    cursor: pointer;
    transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
    -webkit-box-shadow: 0 0 24px 0 rgba(179, 187, 203, 0.5);
    -moz-box-shadow: 0 0 24px 0 rgba(179, 187, 203, 0.5);
    box-shadow: 0 0 24px 0 rgba(179, 187, 203, 0.5);
  }
}

.g-toggle-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row nowrap;
  padding: 16px;
}

.g-text-area-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-flow: column wrap;
  width: 100%;
  padding: 16px 0 16px 0;
}

.g-datetime-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-flow: column nowrap;
  width: 100%;
  padding: 6px 0 0 0;
}

.antd-datetime-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-flow: column nowrap;
  width: 100%;
  padding: 0 16px 0 16px;
}

.simple-select {
  background: transparent;
  width: 100%;
  justify-content: center;
}

.margin-t-minus-10 {
  margin-top: -6px !important;
}

.companyCard {
  width: 200px;
  height: 180px;
  border-radius: 16px;
  background: var(--ion-item-background);
  padding: 10px;
  text-align: center;

  &.companyCard:hover {
    cursor: pointer;
    transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
    -webkit-box-shadow: 0 0 24px 0 rgba(179, 187, 203, 0.5);
    -moz-box-shadow: 0 0 24px 0 rgba(179, 187, 203, 0.5);
    box-shadow: 0 0 24px 0 rgba(179, 187, 203, 0.5);
  }
}

.rectangleCard {
  width: 160px;
  height: 160px;
  border-radius: 16px;
  background: var(--ion-item-background);
  padding: 10px;
  text-align: center;

  &.rectangleCard:hover {
    cursor: pointer;
    transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
    -webkit-box-shadow: 0 0 24px 0 rgba(179, 187, 203, 0.5);
    -moz-box-shadow: 0 0 24px 0 rgba(179, 187, 203, 0.5);
    box-shadow: 0 0 24px 0 rgba(179, 187, 203, 0.5);
  }
}

.selectedCompany {
  border-width: 2px;
  border-color: var(--ion-color-success-shade);
  border-radius: 16px;
  border-style: solid;
}

.docForm {
  width: 100%;
  background: var(--ion-item-background);
  box-shadow: 0 0 1px 0 var(--ion-color-light-contrast);
}

.circle-button-40 {
  --border-radius: 50px;
  --padding-top: 0;
  --padding-right: 0;
  --padding-left: 0;
  --padding-bottom: 0;
  height: 100px !important;
  width: 100px !important;
}

.my-ant-select {
  width: 100%;
  background-color: transparent;
  height: 100%;
  font-size: 16px;
  font-family: Manrope, serif !important;
  color: var(--ion-text-color);
}

.disabled-section {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 5;
  background-color: var(--ion-color-medium);
  opacity: 0.4;
}

.delete-product {
  position: absolute;
  right: 8px;
  top: 8px;
  border: 1px solid transparent;

  &:focus-within {
    border: 1px solid var(--ion-color-success);
    border-radius: 20px;
    background: var(--ion-color-step-300);
  }
}

.delete-customer-shipping {
  position: absolute;
  right: -16px;
  top: -16px;
  border: 1px solid transparent;
  background: var(--ion-item-background);
  border-radius: 20px;

  &:focus-within {
    border: 1px solid var(--ion-color-success);
    border-radius: 20px;
    background: var(--ion-color-step-300);
  }
}

.add-fab {
  &:focus-within {
    border: 1px solid var(--ion-color-success);
    border-radius: 20px;
    background: var(--ion-color-step-300);
  }
}

.border-1-solid {
  border: 1px solid var(--ion-color-step-300);
}

.option-border-mine {
  border: 1px solid var(--ion-color-tertiary);
  padding: 2px;
  border-radius: 6px;
}

.option-border-customer {
  border: 1px solid var(--ion-color-secondary);
  padding: 2px;
  border-radius: 6px;
}

.option-border-new {
  border: 1px solid var(--ion-color-warning);
  padding: 2px;
  border-radius: 6px;
}

.ion-delete-button {
  --color: var(--ion-text-color);
  --background: transparent;
  --border-style: solid;
  --border-width: 1px;
  --border-color: var(--ion-color-danger);
}

.ion-info-button {
  --color: var(--ion-text-color);
  --background: transparent;
  --border-style: solid;
  --border-width: 1px;
}

.ion-filter-button {
  color: var(--ion-text-color) !important;
  background: transparent !important;
  --background: transparent !important;
  border-style: solid !important;
  --border-style: solid !important;
  border-width: 1px !important;
  --border-width: 1px !important;
  border-color: var(--ion-color-step-600) !important;
  --border-color: var(--ion-color-step-600) !important;
}

.ion-sort-button {
  width: 38px;
  height: 38px;
  background: transparent !important;
  --background: transparent !important;
  color: var(--ion-text-color) !important;
  --color: var(--ion-text-color) !important;
  /* border-style: solid !important; */ // Not Needed
  --border-style: solid !important;
  border-width: 1px !important;
  --border-width: 1px !important;
  border-color: var(--ion-color-step-600) !important;
  --border-color: var(--ion-color-step-600) !important;
  --box-shadow: none;
}

.border-1-rad-10 {
  border: 1px solid var(--b-border-color);
  border-radius: 10px;
}

.darkText {
  color: #380808 !important;
}

.viewFieldActionButton {
  top: 0;
  right: 0;
}

.inputLabelActionButton {
  top: -10px;
  right: -10px;
}
