.a4-container {
  /* width: 210mm; */
  /* height: 297mm; */
  width: 800px;
  height: 1160px;
  background: var(--ion-item-background);
  flex-direction: column;
  position: relative;
  transform-origin: top center;
}

.a4-container-only-width {
  background: var(--ion-item-background);
  width: 800px;
  flex-direction: column;
  position: relative;
}

.billease-logo-in-doc {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.page-number-container {
  position: absolute;
  top: 2px;
  left: 6px;
}

.smallTitleRegular {
  font-size: 10px;
}

.section {
  display: flex;
  padding: 10px;
  margin: 10px 10px 30px 10px;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.footer {
  position: absolute;
  bottom: 4px;
  left: 20px;
}

.copyright {
  position: absolute;
  bottom: -16px;
  right: 0;
}

.notes {
  position: relative;
  margin-top: 4px;
  border: 1px var(--ion-color-light-contrast) solid;
  margin-bottom: 80px;
}

.container {
  display: flex;
  border-width: 1px;
  border-radius: 6px;
  flex-direction: column;
  padding: 2px;
  width: 370px;
}

.containerExtraInfo {
  display: flex;
  border-width: 1px;
  border-radius: 6px;
  flex-direction: column;
  padding: 2px;
  width: 370px;
}

.placeholder {
  width: 90px;
}

.maxWidth240 {
  max-width: 300px;
}

.maxWidth200 {
  max-width: 200px;
}

.maxWidth160 {
  max-width: 160px;
}

.notesSection {
  display: flex;
  flex-direction: column;
  height: 68px;
}

.notesTitle {
  display: flex;
  padding-left: 4px;
  background-color: #9d9fa6;
  justify-content: flex-start;
}

.width400 {
  width: 400px;
}

.width200 {
  width: 200px;
}

.width180 {
  width: 180px;
}

.width140 {
  width: 140px;
}

.width120 {
  width: 120px;
}

.width110 {
  width: 110px;
}

.width100 {
  width: 100%;
}

.width80 {
  width: 80px;
}

.width64 {
  width: 64px;
}

.width60 {
  width: 60px;
}

.width58 {
  width: 58px;
}

.width40 {
  width: 40px;
}

.totalCostContainer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 460px;
}

.separator {
  height: 0.1px;
  background-color: #1e2023;
  width: 100%;
  margin-top: 4px;
  margin-bottom: 4px;
}

.tableContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  background-color: #c9cace;
  padding: 2px;
}

.tableTitle {
  border-right: 1px white solid;
  height: 34px;
}

.centerTitle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-self: center;
}

.center {
  display: flex;
  align-self: center;
  justify-content: center;
  align-items: center;
}

.productIndexSection {
  position: absolute;
  left: -16px;
}

.boldText0 {
  font-size: 9px;
  font-family: Manrope-Bold, serif !important;
}

.boldText10 {
  font-size: 10px;
  font-family: Manrope-Bold, serif !important;
}

.boldText1 {
  font-size: 11px;
  font-family: Manrope-Bold, serif !important;
}

.boldText3 {
  font-size: 11px;
  font-family: Manrope-Bold, serif !important;
}

.normalText0 {
  font-size: 10px;
  font-family: Manrope, serif !important;
}

.normalText1 {
  font-size: 11px;
  font-family: Manrope, serif !important;
}

.normalText2 {
  font-size: 11px;
  font-family: Manrope, serif !important;
}

.simpleBorder {
  border: 1px var(--ion-color-light-contrast) solid;
  border-radius: 6px;
  padding: 2px;
}

.normalText8px {
  font-size: 8px;
  font-family: Manrope, serif !important;
}

.boldText8px {
  font-size: 8px;
  font-family: Manrope-Bold, serif !important;
}
