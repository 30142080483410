ion-menu ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

ion-menu.md ion-content,
ion-menu.ios ion-content {
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 4px;
  --padding-bottom: 20px;
}

ion-menu.ios ion-content {
  --padding-top: 16px;
}

ion-menu ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
  border-radius: 4px;
}

ion-menu ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.14);
}

ion-menu ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu ion-item ion-icon {
  color: #616e7e;
}

ion-item.selected {
  --color: var(--ion-color-primary);
}

.logoContainer {
  position: absolute;
  bottom: 0;
  left: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
