.full-screen {
  position: fixed;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  z-index: 99001;
  background: rgba(51, 51, 51, 0.34);
  display: flex;
  justify-content: center;
  align-items: center;
}

.background-full {
  background: var(--ion-item-background-loading);
}

.loader-body {
  position: relative;
}

.loading-indicator {
  z-index: 99001;
  background: rgba(51, 51, 51, 0.64);
}

.positionRelative {
  position: relative;
}

.positionAbsolute {
  position: absolute;
}

.errorForm {
  position: absolute;
  top: 64px;
  color: var(--ion-color-danger);
}

.entityFormId {
  position: absolute;
  top: 64px;
  left: 12px;
  z-index: 100;
}

.deleteIcon {
  position: absolute;
  top: 64px;
  right: 12px;
  z-index: 100;
}

.noTextTransformation {
  text-transform: none !important;
}
