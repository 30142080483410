@font-face {
  font-family: 'Manrope'; //This is what we are going to call
  src: url('./assets/fonts/Manrope-Regular.ttf');
}

@font-face {
  font-family: 'Manrope-Medium'; //This is what we are going to call
  src: url('./assets/fonts/Manrope-Medium.ttf');
}

@font-face {
  font-family: 'Manrope-Bold'; //This is what we are going to call
  src: url('./assets/fonts/Manrope-Bold.ttf');
}

* {
  font-family: Manrope, serif !important;
}

ion-content {
  font-family: Manrope, serif !important;
}

// NORMAL TEXT
.normalText0 {
  font-size: 7px;
  font-family: Manrope, serif !important;
  color: var(--ion-color-light-contrast);
}

.normalText1 {
  font-size: 8px;
  font-family: Manrope, serif !important;
  color: var(--ion-color-light-contrast);
}

.normalText2 {
  font-size: 10px;
  font-family: Manrope, serif !important;
  color: var(--ion-color-light-contrast);
}

.normalText3 {
  font-size: 12px;
  font-family: Manrope, serif !important;
  color: var(--ion-color-light-contrast);
}

.normalText4 {
  font-size: 14px;
  font-family: Manrope, serif !important;
  color: var(--ion-color-light-contrast);
}

.normalText5 {
  font-size: 16px;
  font-family: Manrope, serif !important;
  color: var(--ion-color-light-contrast);
}

.normalText6 {
  font-size: 18px;
  font-family: Manrope, serif !important;
  color: var(--ion-color-light-contrast);
}

.normalText7 {
  font-size: 20px;
  font-family: Manrope, serif !important;
  color: var(--ion-color-light-contrast);
}

.normalText8 {
  font-size: 22px;
  font-family: Manrope, serif !important;
  color: var(--ion-color-light-contrast);
}

.normalText9 {
  font-size: 24px;
  font-family: Manrope, serif !important;
  color: var(--ion-color-light-contrast);
}

// MEDIUM TEXT
.mediumText1 {
  font-size: 8px;
  font-family: Manrope-Medium, serif !important;
  color: var(--ion-color-light-contrast);
}

.mediumText2 {
  font-size: 10px;
  font-family: Manrope-Medium, serif !important;
  color: var(--ion-color-light-contrast);
}

.mediumText3 {
  font-size: 12px;
  font-family: Manrope-Medium, serif !important;
  color: var(--ion-color-light-contrast);
}

.mediumText4 {
  font-size: 14px;
  font-family: Manrope-Medium, serif !important;
  color: var(--ion-color-light-contrast);
}

.mediumText5 {
  font-size: 16px;
  font-family: Manrope-Medium, serif !important;
  color: var(--ion-color-light-contrast);
}

.mediumText6 {
  font-size: 18px;
  font-family: Manrope-Medium, serif !important;
  color: var(--ion-color-light-contrast);
}

.mediumText7 {
  font-size: 20px;
  font-family: Manrope-Medium, serif !important;
  color: var(--ion-color-light-contrast);
}

.mediumText8 {
  font-size: 22px;
  font-family: Manrope-Medium, serif !important;
  color: var(--ion-color-light-contrast);
}

.mediumText9 {
  font-size: 24px;
  font-family: Manrope-Medium, serif !important;
  color: var(--ion-color-light-contrast);
}

// BOLD TEXT
.boldText1 {
  font-size: 8px;
  font-family: Manrope-Bold, serif !important;
  color: var(--ion-color-light-contrast);
}

.boldText2 {
  font-size: 10px;
  font-family: Manrope-Bold, serif !important;
  color: var(--ion-color-light-contrast);
}

.boldText3 {
  font-size: 12px;
  font-family: Manrope-Bold, serif !important;
  color: var(--ion-color-light-contrast);
}

.boldText4 {
  font-size: 14px;
  font-family: Manrope-Bold, serif !important;
  color: var(--ion-color-light-contrast);
}

.boldText5 {
  font-size: 16px;
  font-family: Manrope-Bold, serif !important;
  color: var(--ion-color-light-contrast);
}

.boldText6 {
  font-size: 18px;
  font-family: Manrope-Bold, serif !important;
  color: var(--ion-color-light-contrast);
}

.boldText7 {
  font-size: 20px;
  font-family: Manrope-Bold, serif !important;
  color: var(--ion-color-light-contrast);
}

.boldTextHead {
  font-size: 48px;
  font-family: Manrope-Bold, serif !important;
  color: var(--ion-color-light-contrast);
}

.b-select-label {
  position: absolute;
  top: -10px;
  left: 12px;
  z-index: 10;
  background-color: var(--ion-background-color);
  border-radius: 20px;
  padding: 1px 4px 1px 4px;
  font-size: calc(1em * 0.75);
}

.b-label-small {
  position: absolute;
  top: -10px;
  left: 12px;
  z-index: 10;
  background-color: var(--ion-background-color);
  border-radius: 20px;
  padding: 1px 4px 1px 4px;
  font-size: calc(1em * 0.75);
}

.text-no-wrap {
  text-wrap: nowrap;
}

.ion-select-option-16 {
  font-size: 16px !important;
}
