// HEIGHT

.height-22px {
  height: 22px !important;
  min-height: 22px !important;
}

.height-48px {
  height: 48px !important;
  min-height: 48px !important;
}

.height-50px {
  height: 50px !important;
  min-height: 50px !important;
}

.height-56px {
  height: 56px !important;
  min-height: 56px !important;
}

.height-64px {
  height: 64px !important;
  min-height: 64px !important;
}

.height-100 {
  height: 100%;
}

// WIDTH

.width-40px {
  width: 40px;
}

.width-50 {
  width: 50%;
}

.width-60px {
  width: 60px;
}

.width-80 {
  width: 80%;
}

.width-80px {
  width: 80px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.width-90px {
  width: 90px;
}

.width-100 {
  width: 100%;
}

.width-100px {
  width: 100px;
}

.width-110px {
  width: 110px;
}

.width-120px-simple {
  width: 120px;
}

.width-120px {
  width: 120px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.width-120px {
  width: 120px;
}

.width-140px-simple {
  width: 140px;
}

.width-180px {
  width: 180px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.width-196px {
  width: 196px;
}

.width-200px {
  width: 200px;
}

.width-product-1line-200 {
  width: 200px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.width-240px {
  width: 240px;
}

.min-width-240px {
  min-width: 240px;
}

.width-250px {
  width: 250px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.width-300px {
  width: 300px;
}

.min-width-300px {
  min-width: 300px;
}

.width-300px-1line {
  width: 300px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.width-320px {
  width: 320px;
}

.width-product-1line {
  width: 340px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.width-350px {
  width: 350px;
}

.min-width-460px {
  min-width: 460px;
}

.max-width-460px {
  max-width: 460px;
}

.width-418px {
  width: 418px;
}

.width-460px {
  width: 460px;
}

.minWidth860 {
  min-width: 860px;
}

.max-width-900 {
  max-width: 900px;
}
